// Route guard middleware for auth - used on platforms pages behind the login strictly requiring login
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/store/auth';
import { useUiStore } from '@/store/ui';

export default defineNuxtRouteMiddleware(async() => {
  const authStore = useAuthStore();
  const {
    user,
    isUserLogged,
  } = storeToRefs(authStore);
  const { getUser, } = authStore;
  const uiStore = useUiStore();
  const {
    redirecting,
  } = storeToRefs(uiStore);

  try {
    if (!user.value) {
      await getUser();
    }

    if (!isUserLogged.value) {
      throw new Error('User not logged in');
    }
  } catch (err) {
    redirecting.value = true;
    navigateTo('/');
    setTimeout(() => {
      redirecting.value = false;
    }, 2000);
  }
});
